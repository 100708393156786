.listing{
  #titlebar{
    margin-bottom: 0;
    padding: 20px;
  }
  .content-wrapper{
    background-color: #ecf0f3;
    .listing-count{
      font-size:14px;
      padding: 8px 0;
    }

    .firm-listing-container{
      .firm-list-item{
        margin-bottom: 20px;
        background-color: $color-white;
        display: inline-block;
        height: auto;
        width: 100%;
        border-radius: 4px;
        transform: translate3d(0,0,0);
        transition: transform .3s;
        position: relative;
        z-index: 100;
        &:hover{
          transform: translate3d(6px,0,0);
        }
        a.action{
          display: block;
          position: absolute;
          top:0;
          left:0;
          width:100%;
          min-height: 180px;
          text-indent: -99999px;
        }
        .list-item-image{
          width: 25%;
          min-height: 180px;
          padding: 0 15px;
          float:left;
          a{
            display: block;
          }
          img{
            max-width: 90%;
            max-height: 95px;
            &.grayscale{
              -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
              filter: grayscale(100%);
              @include opacity(0.2);
            }
          }
        }
        .list-item-content{
          float:left;
          width: 57%;
          .listing-item-inner{
            width: 100%;
            padding: 10px 10px;
            min-height: 180px;
            position: relative;
            .h-center-content{
              width: 75%;
            }
            span{
              font-size:14px;
            }
            h3{
              color:$color-black !important;
              margin: 0 0 6px 0;
              font-size:20px;
              font-weight: bold;
            }
            p.status{
              color:#00c483;
              font-size:19px;
              margin-bottom: 4px;
              span{
                color:$color-gray-2;
                margin-left: 4px;
                font-size:15px;
              }
            }
            .verified-icon{
              color:#DADADA;
              &.approved{
                color:#00c483;
              }
            }
            .blinking{
              @include animation(blinking 1s infinite);
              @keyframes blinking{
                0%  { color: #FFF;	         }
                40% { color: #FFF;	         }
                50% { color: transparent;	 }
                90% { color: transparent;    }
              }
            }
            .action-wrapper{
              margin-top: 6px;
              a{
                display: block;
                font-size:12px;
                text-align: center;
                font-weight: bold;
                @include border-radius(50px);
                &.ara{
                  background-color: #d40808;
                  color:#FFF;
                }
                &.teklif{
                  background-color: #ffcc00;
                  color:#000;
                }
                &.tarif{
                  background-color: #00c483;
                  color:#FFF;
                }
              }
            }
            .approved-business{
              position: absolute;
              top: 50%;
              margin-top: -55px;
              right: 3%;
              width: 20%;
              height: 110px;
              background: url('../images/onayli-oto-ekspertiz-merkezi.png') no-repeat center right;
              background-size: contain;
              text-indent: -9999px;
            }
          }
        }
        .list-item-price{
          float:left;
          width: 18%;
          position: relative;
          .price-wrapper{
            border-left: 1px solid #ecf0f3;
            min-height: 180px;
            padding: 0 10px;
            width: 100%;
            text-align: right;
            .title{
              font-size:11px;
            }
            .price{
              font-size:30px;
              font-weight: bold;
              color:$color-black;
              &.teklif{
                font-size:17px;
                small{
                  display: none;
                }
              }
            }
            i.tip{
              position: absolute;
              top: auto;
              left: auto;
              right: 10px;
              bottom: 10px;
              background-color: $color-gray;
            }
            .km{
              position: absolute;
              bottom:0;
              left: 0;
              width:100%;
              font-size:15px;
              font-weight: 700;
              i.sl{
                font-size:10px;
              }
              small{
                font-weight: 400;
                font-size:11px;
              }
            }
          }
        }
        .more{
          float:left;
          width: 100%;
          a{
            display: block;
            background-color: #1d89ca;
            text-align: center;
            font-size: 12px;
            padding: 7px 10px;
            color: #FFFFFF;
            letter-spacing: 2px;
          }
        }
      }
    }

    .text-content-wrapper{
      background-color: $color-white;
      padding: 20px 20px 0 20px;
      margin-bottom: 45px;
      font-size:13px;
      line-height: 18px;
      @include border-radius(6px);
      .color{
        color: $color-green;
      }
      a{
        color:$color-blue;
        &:hover{
          text-decoration: underline;
        }
      }
      p{
        font-size:13px;
        line-height: 18px;
        margin-bottom: 10px;
      }
      ul,ol{
        font-size:13px;
        line-height: 20px;
      }
      h2,h3,h4,h5,h6{
        margin: 10px 0;
        font-weight: 600;
      }
      h2{
        font-size:19px;
      }
      h3{
        font-size:18px;
      }
      h4{
        font-size:17px;
      }
      h5{
        font-size:16px;
      }
      h6{
        font-size:15px;
      }
    }

  }
  @media (max-width: 767px){
    #titlebar{
      padding: 10px;
      h1{
        font-size:22px;
      }
    }
    .content-wrapper{
      #breadcrumbs{
        margin: 0 0 10px 0;
      }
      .firm-listing-container{
        .firm-list-item{
          &:hover{
            transform: translate3d(0,0,0);
          }
          .list-item-image{
            width: 100%;
            padding: 15px;
            min-height: 90px;
            border-bottom:1px solid #ddd;
            .h-center-content{
              text-align: left !important;
            }
            img{
              max-width:50%;
              max-height: 60px;
            }
          }

          .list-item-content{
            width: 73%;
            .listing-item-inner{
              padding: 15px;
              min-height: auto;
              border-right: 1px solid #ecf0f3;
              position: inherit !important;
              .h-center-content{
                width: auto !important;
              }
              h3{
                font-size:18px;
                line-height: 24px;
              }
              p.status{
                font-size:17px;
                span{
                  font-size:13px;
                }
              }
              span{
                font-size:13px;
              }
              .verified-icon{
                position: absolute;
                top: 10px;
                right: 10px;
              }
              .approved-business{
                top:10px;
                margin-top: 0;
                right: 10px;
                height: 70px;
              }
            }

          }
          .list-item-price{
            width: 27%;
            .price-wrapper{
              min-height: 125px;
              padding: 0 5px;
              border:none;
              .listing-badge{
                padding-right: 5px;
              }
              .title{
                font-size:8px;
              }
              .price{
                font-size:20px;
                &.teklif{
                  font-size:12px;
                }
              }
            }
          }
        }
      }
    }
  }
}