.city-lists{
  .headline{
    a{
      color:$color-blue;
    }
  }
  .list-style-1{
    margin: 0;
    padding: 0;
    list-style: none;
    li{
      border-bottom:1px solid #ededed;
      width: 20%;
      @media (max-width: 1239px){
        width: 25%;
      }
      @media (max-width: 767px){
        width: 50%;
      }
      float:left;
      &:last-child{
        border:0;
      }
      a{
        display: block;
        font-size:14px;
        padding: 4px 0;
        span.im{
          font-size:16px;
          margin-right: 5px;
          margin-left: 5px;
        }
        &:hover{
          background-color: $color-gray;
        }
      }
    }
  }
}