#header-container{
  #header{
    padding: 18px 0 14px;
    #logo{
      margin-top:0;
      a{
        display: block;
      }
      img{
        max-height: 50px;
      }
    }
    .right-side{
      .header-widget{
        .button{
          overflow: visible !important;
          span{
            position: absolute;
            top:-12px;
            left:6px;
            font-size:11px;
            background-color: $color-orange;
            @include border-radius(50px);
            line-height: 20px !important;
            padding: 0 15px;
            color:$color-black;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px){

  .mm-menu{
    .mm-listview{
      li{
        a{
          .sl{
            font-size:12px;
          }
          &.rapor-sorgula{
            background-color: $color-blue;
          }
          &.firma-ekle{
            background-color: $color-orange;
            color:$color-black;
          }
        }
      }
    }
  }

}

@media (min-width: 1240px) and (max-width: 1370px){

  #header{
    font-size:15px;
    #logo{
      margin-top: 4px !important;
      margin-right: 20px;
      a{
        img{
          height: 41px;
        }
      }
    }
  }

}