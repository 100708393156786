.main-search-container{
  .categories-quick-links{
    position: absolute;
    left:0;
    bottom:0;
    width: 100%;
    z-index: 0;
    @include background-opacity($color-black, 0.5);
    ul{
      margin: 0;
      padding: 0;
      list-style: none;
      li{
        display:inline-block;
        a{
          padding: 15px 18px;
          display: block;
          font-size:16px;
          color:$color-white;
          position: relative;
          span{
            position: absolute;
            top:0;
            left:10px;
            &.icon{
              width: 50px;
              height: 100%;
              display:inline-block;
              &.otomobil{
                background: url('../images/otomobil.png') no-repeat left center;
                background-size: contain;
              }
              &.mobil{
                background: url('../images/mobil.png') no-repeat left center;
                background-size: contain;
              }
              &.motorsiklet{
                background: url('../images/motorsiklet.png') no-repeat left center;
                background-size: contain;
              }
            }
          }
          .sl{
            font-size:12px;
            margin-left: 8px;
          }
          &:hover{
            @include background-opacity($color-black, 0.5);
          }
        }
      }
    }
  }
}