.single-page{
  .content-wrapper{
    background-color: #ecf0f3;
    .listing-titlebar{
      padding: 20px !important;
      .logo{
        height: 143px;
        width: 100%;
        padding: 10px;
        border:1px solid $color-gray;
        @media (max-width: 767px){
          margin: 0 auto;
          height: 100px;
          img{
            max-height: 80px;
          }
        }
        img{
          max-width: 85%;
          max-height: 95px;
        }
      }
      .listing-titlebar-title{
        height: 143px;
        @media (max-width: 767px){
          margin: 0 auto;
          text-align: center;
        }
        h1{
          color:$color-black !important;
          i.verified-icon{
            color:#DADADA;
            &.approved{
              color:#00c483;
            }
          }
        }
        span.listing-tag{
          padding: 4px 9px !important;
          background-color: #19b453 !important;
          border:none !important;
          color:$color-white !important;
          &.closed{
            background-color: #d40808 !important;
          }
        }
      }
      .profile-action{
        .phone, .whatsapp{
          display: block;
          padding: 9px 4px 7px 70px;
          text-align: left;
          font-size:17px;
          line-height: 23px;
          font-weight: 600;
          position: relative;
          i{
            position: absolute;
            top:10px;
            left:12px;
            font-size:22px;
            height: 40px;
            line-height: 40px;
            padding-right: 12px;
            border-right: 1px solid #FFF;
          }
          div{
            display: block;
            font-size:13px;
            font-weight: normal;
          }
        }
        .phone{
          border:1px solid $color-orange;
          background-color: $color-orange;
          color:$color-black;
          i{
            border-right: 1px solid $color-black;
            font-size:30px;
          }
          div{
            color:$color-black;
          }
          &.mobile{
            display: none;
          }
          @media (max-width: 767px){
            &.mobile{
              display: block;
            }
            &.desktop{
              display: none;
            }
          }
        }
        .whatsapp{
          color:$color-white;
          border:1px solid #19b453;
          background-color: #19b453;
          @media (max-width: 991px){
            margin-top: 15px;
          }
          @media (max-width: 767px){
            margin-top: 0px;
          }
          i{
            font-size:34px;
          }
          div{
            color:$color-white;
          }
        }
      }
    }

    .listing-nav-container{
      margin-top: 20px;
      ul.listing-nav{
        margin-bottom: 0px;
        display: block;
        overflow: hidden;
        @media (max-width: 767px){
          display: none;
        }
        li{
          padding: 7px 15px;
          margin: 0 !important;
          border-right: 1px solid #e0e0e0;
          &:last-child{
            border:none !important;
          }
          a{
            padding: 5px 0;
            font-weight: bold;
            font-size:13px;
            border:none !important;
            @include transition(0);

          }
        }
      }
    }

    .listing-section{
      background-color: $color-white;
      padding: 15px;
      margin-bottom: 20px;
      .listing-desc-headline{
        font-size:14px;
        margin: 0 0 15px 0;
        padding: 0 0 15px 0;
        font-weight: bold;
        border-bottom: 1px solid #e0e0e0;
      }

      .social-icons{
        li{
          a{
            zoom: 0.7;

          }
        }
      }

      .services{
        .tabs-nav{
          li{
            margin-bottom: -2px;
            z-index: 0;
            &.active{
              z-index: 2;
              a{
                border:1px solid #e0e0e0;
                border-bottom:transparent;
                &:hover{
                  border:1px solid #e0e0e0;
                  border-bottom:transparent;
                }
              }
            }
            a{
              font-weight: bold;
              background-color: $color-white;
              padding: 0px 25px;
              &:hover{
                border:1px solid transparent;
              }
            }
          }
        }
        .tabs-container{
          .tab-content{
            .listing-features{
              li{
                &::before{
                  @include border-radius(0);
                }
              }
            }
          }
        }
      }

      .pricing-list-container{
        .discount{
          background-color: #00c483;
          padding: 10px 15px;
          color:$color-white;
          .blinking{
            display: inline-block;
            border:2px solid $color-white;
            font-size: 12px;
            color: $color-white;
            padding: 5px 15px;
            @include border-radius(50px);
            letter-spacing: 1px;
            line-height: 16px;
            @include animation(blinking 1s infinite);
            @keyframes blinking{
              0%  { color: #FFF;	         }
              40% { color: #FFF;	         }
              50% { color: transparent;	 }
              90% { color: transparent;    }
            }
          }
        }
        .notification{
          &.notice{
            padding: 10px 16px;
            text-align: center;
            p{
              color:$color-black !important;
              font-size:13px;
            }

          }
        }
        h4{
          padding: 15px !important;
          font-weight: 500;
        }
        .price-box{
          padding: 15px 15px;
          &.light-bg{
            background-color: #f9f9f9 !important;
          }
          p{
            font-size:12px;
            line-height: 18px;
          }
          .title{
            width: 100%;
            height: 55px;
            .blinking{
              display: inline-block;
              background-color: #00c483;
              font-size: 12px;
              color: $color-white;
              padding: 5px 15px;
              @include border-radius(50px);
              letter-spacing: 1px;
              line-height: 16px;
              @include animation(blinking 1s infinite);
              @keyframes blinking{
                0%  { color: #FFF;	         }
                40% { color: #FFF;	         }
                50% { color: transparent;	 }
                90% { color: transparent;    }
              }
            }
          }
          .price-wrapper{
            width: 100%;
            height: 60px;
            .price-title{
              font-size:13px;
              line-height: 20px;
              font-weight: bold;
              float:left;
              padding: 10px;
              border:1px solid #ededed;
              background-color: $color-white;
              div{
                font-weight: normal;
                font-size:12px;
              }
            }
            .price{
              float:right;
              height: 100%;
              line-height: 60px;
              font-size:21px;
              padding: 0 15px;
              background-color: #FFF;
              color: $color-black;
              border:1px solid #ddd;
              &.expensive{
                background-color: #ffcc00;
                color:$color-black;
              }
            }
            .action{
              float:left;
              a{
                display: block;
                font-size:13px;
                font-weight: bold;
                background-color: $color-black;
                padding: 3px 10px;
                @include border-radius(0px 0 0 0);
                color:$color-white;
              }
            }
          }
        }
      }

      &.maps{
        .orientation{
          background-color: $color-orange;
          .title{
            padding: 15px 15px;
            color:$color-black;
            letter-spacing: 1px;
            font-size:17px;
          }
          .action{
            .phone{
              padding: 15px 10px;
              font-size:20px;
              letter-spacing: 1px;
              color:$color-black;
              font-weight: bold;
              float:left;
            }
            .button{
              float:left;
              padding: 15px 12px;
              font-size:20px;
              color:$color-white;
              background-color: $color-red;
            }
            .whatsapp{
              display: inline-block;
              background-color: #00c483;
              color:$color-white;
              font-size:14px;
              padding: 7px 15px;
              @include border-radius(50px);
              margin-bottom: 15px;
              span{
                font-size:20px;
              }
            }
          }
        }
      }

      .listing-hours-wrapper{
        border-left: 1px solid #e0e0e0;
        overflow: hidden;
        margin-bottom: 15px;
        .item{
          width: 14.28%;
          float:left;
          text-align: center;
          border-right: 1px solid #e0e0e0;
          font-size:13px;
          height: 81px;
          .day{
            font-weight: bold;
          }
          .status{
            padding: 4px 7px;
            @include border-radius(50px);
            font-size:10px;
            color:$color-white;
            &.close{
              background-color: #d40808 !important;
            }
            &.open{
              background-color: #00c483 !important;
            }
          }
        }
        @media (max-width: 767px){
          margin-top: -15px;
          .item{
            width: 33.28%;
            border-bottom:1px solid #e0e0e0;
            padding: 5px 0;
            height: 90px;
          }
        }
      }

    }

    .business-examples{
      padding: 15px 15px 20px 15px;
      background-color: $color-white;

      #singleListingMap-container{
        position: relative;
        #directions{
          position: absolute;
          top:auto;
          left:0;
          right: 0;
          bottom:-20px;
          margin: auto;
          width: 100%;
          background-color: $color-blue;
          text-align: center;
          font-size:18px;
          padding: 15px 10px;
          color:$color-white;
          letter-spacing: 2px;
        }
        .direction-status{
          position: absolute;
          left:10px;
          top:10px;
          text-align: center;
          .km,.munites{
            background-color: $color-white;
            font-size:18px;
            font-weight: bold;
            padding: 0 10px;
            color:$color-white;
            text-align: right;
            span{
              font-size:13px;
              font-weight: normal;
            }
          }
          .munites{
            border-bottom:1px solid $color-gray;
            padding: 4px 6px 2px 6px;
            background-color:$color-blue;
          }
          .km{
            padding: 4px 6px 4px 6px;
            background-color: $color-gray-3;
          }
        }
      }

      h5.title{
        margin: 15px 0;
        font-size:17px;
        i{
          margin-right: 10px;
          font-size:20px;
        }
      }
      .slick-dots{
        bottom:10px !important;
      }
    }

    .business-detail{
      background-color: $color-white;
      padding:20px;
      font-size:17px;
      .title{
        padding-left:40px;
        position: relative;
        span{
          position: absolute;
          top:8px;
          left:0;
          font-size:25px;
        }
      }
      .text{
        text-align: right;
        font-size:16px;
        &.adres,&.website{
          a{
            color:$color-blue;
          }
        }
        &.tel{
          a{
            color:#fe015b;
          }
        }
        .social-icons{
          overflow: hidden;
          margin:0;
          text-align: right;
          height: 35px;
          li{
            margin-right: 0;
            margin-left: 5px;
          }
          a{
            zoom:0.8;
          }
        }
      }
      table{
        tr{
          border-bottom:2px solid #ecf0f3;
          td{
            padding: 9px 0;
          }
          &:last-child{
              border-bottom:0;
          }
        }
      }
    }

    .noterler{
      padding: 0 15px 15px 15px;
      background-color: $color-white;
      a {
        display: block;
        background-color: #d53824;
        text-align: left;
        font-size:18px;
        padding: 15px 10px 15px 95px;
        color:$color-white;
        letter-spacing: 2px;
        position: relative;
        span{
          position: absolute;
          top: 10px;
          left: 10px;
          border:1px solid $color-white;
          line-height: 15px;
          font-size:13px;
          padding: 10px 7px;
          background-color: #d53824;
        }
      }
    }

    .statistics{
      margin-top: 30px;
      background-color: $color-white;
      padding: 20px 20px 0 20px;
      .box{
        margin-bottom: 20px;
        text-align: center;
      }
      i{
        background-color: #ecf0f3;
        width: 50px;
        height: 50px;
        display: block;
        margin: 0 auto 8px auto;
        @include border-radius(50px);
        line-height: 50px;
      }
      i.sl{
        font-size:25px;
      }
      i.fa{
        font-size:25px;
      }
      .call{
        i.sl{
          font-size:22px;
        }
      }
      p{
        font-weight: bold;
        margin-bottom: 0;
        line-height: 19px;
        span{
          display: block;
          font-weight: normal;
          font-size:12px;
          margin-top: 4px;
        }
      }
    }

    .pricing {
      text-align: center;
      border: 1px solid #f0f0f0;
      color: #777;
      font-size: 14px;
      padding-left: 0;
      margin-bottom: 30px;
      li {
        list-style: none;
        padding: 7px 5px;
        border-bottom: 1px solid #f0f0f0;
        + li {
          border-bottom: 1px solid #f0f0f0;
        }
        &.title{
          padding: 20px 5px 10px 5px;
          border-bottom: 1px solid #f0f0f0;
        }
        &.price{
          padding: 5px 5px 20px 5px;
          border-bottom: 1px solid #f0f0f0;
        }
        &.action{
          padding:22px 5px;
        }
      }
      big {
        font-size: 28px;
      }
      small {
        font-size: 14px;
      }
      h3 {
        margin-bottom: 0;
        font-size: 36px;
      }
      span {
        font-size: 12px;
        color: #999;
        font-weight: normal;
      }
      a {
        &.konum {
          width: auto;
          margin: auto;
          font-size: 15px;
          font-weight: bold;
          border-radius: 50px;
          color: #fff;
          padding: 11px 24px;
          background: #aaa;
          opacity: 1;
          transition: opacity .2s ease;
          border: none;
          outline: none;
        }
        &:hover {
          opacity: .9;
        }
        &:active {
          box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.1);
        }
      }
    }

    /* pricing color */

    .p-green {
      big, h3, small, .collapseBtn {
        color: #4c7737;
      }
      a.konum {
        background: #4c7737;
      }
    }

    .p-yel {
      big, h3, small, .collapseBtn {
        color: #ffcc00;
      }
      a.konum {
        background: #ffcc00;
      }
    }

    .p-red {
      big, h3, small, .collapseBtn {
        color: #e13c4c;
      }
      a.konum {
        background: #e13c4c;
      }
    }

    .p-blue {
      big, h3, .collapseBtn {
        color: #3f4bb8;
      }
      a {
        background: #3f4bb8;
      }
    }

  }
}

.listing-nav-container{
  &.cloned{
    &.stick{
      ul.listing-nav{
        font-size:13px;
        font-weight: bold;
        li{
          border-right: 1px solid #e0e0e0;
          margin-right: 0;
          a{
            padding: 10px 20px;
            border:none !important;
            &.active{
              background-color: $color-blue;
              color:$color-white;
            }
          }
        }
      }
    }
  }
}

.share-buttons{
  &.tags{
    li{
      a{
        &:hover{
          background-color: $color-gray-3;
        }
      }
    }
  }
}