.appointment{

  .randevu-step-wrapper{
    margin: 20px 0 40px 0;
    .randevu-content{
      padding: 0 0 0 55px;
      position: relative;
      margin-bottom: 20px;
      &:last-child{
        &:before{
          content: '';
          display: none;
        }
      }
      &:before{
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: $color-gray;
        top:30px;
        left:20px;
      }
      &.complate{
        .number{
          background-color: $color-green-soft;
        }
        .title{
          color:$color-green-soft;
        }
        .content{
          display: block;
          border-color:$color-green-soft;
          .inner{
            display: none;
          }
          .complating{
            display: block;
            position: relative;
            padding-right: 110px;
            .edit{
              margin: 0;
              position: absolute;
              right: 0;
              padding: 0 12px 0;
              font-size:11px;
              background-color: $color-green-soft;
            }
          }
          .btnMore{
            display: none;
          }
        }
      }
      &.active{
        .number{
          background-color: $color-blue;
        }
        .title{
          color:$color-blue;
        }
        .content{
          display: block;
          border-color:$color-blue;
          padding-bottom: 64px;
        }
      }
      .number{
        width: 42px;
        height: 42x;
        background-color: $color-gray-2;
        display: block;
        font-size:20px;
        font-weight: bold;
        color:$color-white;
        text-align: center;
        line-height: 40px;
        @include border-radius(50px);
        position: absolute;
        top:0;
        left:0;
      }
      .title{
        font-size:20px;
        color: $color-gray-2;
        padding-top: 6px;
        margin-bottom: 10px;
      }
      .content{
        border:2px solid #eee;
        padding: 20px;
        @include border-radius(6px);
        display: none;
        position: relative;

        .inner{
          display: block;
          .error-wrapper{
            font-size:13px;
            color:$color-red;
            margin-top:7px;
          }
          select{
            margin: 0;
          }
        }
        .complating{
          display: none;
        }
        .btnMore{
          background-color: $color-blue;
          position: absolute;
          left:0;
          bottom:0;
          width: 100%;
          text-align: center;
          padding: 10px 0;
          color:$color-white;
          font-weight: bold;
          &:hover{
            @include opacity(0.8);
          }
        }
      }
      .service-type, .packages, .date-wrapper, .time-wrapper{
        h5{
          margin-top: 0;
        }
      }
      .input-list-style{
        border:1px solid $color-gray;
        padding: 10px 15px;
        margin: 5px 0;
        @include border-radius(6px);
        position: relative;
        .pretty{
          width: 100%;
          .state{
            label{
              text-indent: 1.8em;
            }
            .blinking{
              position: absolute;
              right: 0;
              top:-3px;
              font-size: 11px;
              color: $color-white;
              padding: 5px 15px;
              @include border-radius(50px);
              letter-spacing: 1px;
              background-color: $color-green-soft;
              @include animation(blinking 1s infinite);
              @keyframes blinking{
                0%  { color: #FFF;	         }
                40% { color: #FFF;	         }
                50% { color: transparent;	 }
                90% { color: transparent;    }
              }
            }
            .price-wrapper{
              position: absolute;
              right: 0;
              top:-2px;
              div{
                display: inline-block;
                font-size:12px;
                &.expensive-price{
                  font-size:15px;
                  span{
                    color:$color-red;
                    text-decoration: line-through;
                  }
                }
                &.price{
                  font-weight: bold;
                  font-size:20px;
                  color:$color-green-soft;
                }
              }
            }
          }
        }

      }
      &#onay{
        .bilgilendirme{
          a{
            text-decoration: underline;
          }
        }
      }

    }

    #tamamla{
      &.randevu-content{
        .content{
          padding:0px !important;
          border:none !important;
        }
      }
    }
  }

  .appointment-summary{
    background-color: #ecf0f3;
    @include border-radius(10px);
    .boxed-widget{
      background: transparent;
      h3{
        border-color:#ccc;
      }
      ul{
        li{
          padding:4px 0;
          border-bottom:1px dashed #ccc;
        }
      }
    }
  }

}