.fullwidth{
  .headline{
    a{
      color:$color-blue;
    }
  }
}

.campaign-item-list{
  display: block;
  width: 100%;
  @include box-shadow(0, 0, 20px, 0, #dbdbdb);
  @include border-radius(5px);
  @include transform(translate3d(0,0,0));
  @include transition(transform .3s);
  background-color: #fff;
  background: #fff;
  margin-bottom: 5px;
  &:hover{
    @include transform(translate3d(0,-6px,0));
  }
  .title-wrapper{
    padding: 10px 15px;
    overflow: hidden;
    .logo{
      float:left;
      height: 50px;
      margin-right: 10px;
      img{
        max-width: 50px;
        max-height: 50px;
      }
    }
    .title{
      float:left;
      height: 50px;
      font-weight: 400;
      font-size:17px;
      color:$color-blue;
      font-family: Helvetica;
    }
  }
  .image-wrapper{
    position: relative;
    overflow: hidden;
    a{
      display: block;
      overflow: hidden;
      position: relative;
      &:before {
          content: "";
          top: 0;
          position: absolute;
          height: 100%;
          width: 100%;
          z-index: 9;
          background: linear-gradient(to top, rgba(35, 35, 37, 0.9) 0%, rgba(35, 35, 37, 0.45) 35%, rgba(22, 22, 23, 0) 60%, rgba(0, 0, 0, 0) 100%);
          background-color: rgba(35, 35, 37, .2);
          border-radius: 4px 4px 0 0;
          opacity: 1;
      }
      img{
        width: 100%;
        height: auto;
      }
    }
    span.like-icon{
      right: 15px;
      bottom: 15px;
      cursor: pointer;
    }
  }
  .content-wrapper{
    width: 100%;
    height: 60px;
    background: #00c483;
    a{
      display: block;
      padding: 0 15px;
      line-height: 20px;
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      position: relative;
      letter-spacing: .5px;

    }
  }
  .actions-wrapper{
    a{
      padding: 10px;
      display:inline-block;
      @include transition(all .5s);
      .im{
        color:#00c483;
      }
      &:hover{
        color:#00c483;
      }
    }
  }
}

.son-eklenen-firmalar{
  .campaign-item-list{
    .image-wrapper{
      border:1px solid $color-gray;
      a{
        &:before{
          content: none !important;
        }
      }
      .logo{
        width: 100%;
        height: 180px;
        text-align: center;
        img{
          width: auto;
          max-width:80%;
          max-height:120px;
          margin: 0 auto;
        }
      }
    }
  }
}