.user{

  .error-wrapper{
    font-size:13px;
    color:$color-red;
    margin-top:7px;
  }

  .side-menu{
    list-style: none;
    margin: 0;
    padding: 0;
    li{
      margin-top:5px;
      margin-bottom:5px;
      padding: 5px 0 7px 0;
      border-bottom:1px solid #ddd;
      a{
        font-size:16px;
        &:hover{
          color:$color-blue;
        }
        i{
          margin-right: 4px;
        }
      }
    }
  }

  .islem{
    list-style: none;
    margin: 0;
    padding: 0;
    li{
      float: left;
      margin-right: 8px;
      a{
        overflow: hidden;
        display: block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border:1px solid #ddd;
        background-color: #FFF;
      }
    }
  }

}