//
// Variables
//
$color-black        : #000000;
$color-white        : #FFFFFF;
$color-green        : #25ca1d;
$color-orange       : #ffcc00;
$color-red          : #ca1d1d;
$color-gray         : #dadada;
$color-purple       : #771dca;
$color-blue         : #1d89ca;
$color-dark-blue    : #023b5b;

$color-green-soft   : #00c483;

$color-gray-1       : #646464;
$color-gray-2       : #464646;
$color-gray-3       : #2a2a2a;


$pretty--color-info :$color-blue;


html,body{
  font-family: Arial;
}

// Dikey Hizalama
.h-center-wrapper{
  display: table;
  .h-center-content{
    display: table-cell;
    vertical-align: middle;
  }
}
