.query-report{

  .error-wrapper{
    font-size:13px;
    color:$color-red;
    margin-top:7px;
  }

  .page-banner{

    .content{
      position: relative;
      background: url('../images/pages/sorgulama/ekspertiz-raporu-sorgula.png') no-repeat center center;
      background-size: cover;
      @include border-radius(0 0 25px 25px);
      .h-center-wrapper{
        min-height: 150px;
        height: auto;
      }
      .motto{
        color:$color-black;
        font-size:22px;
        line-height: 33px;
        text-align: center;
        padding: 0 70px;
      }
    }
    .price{
      @include border-radius(0 0 25px 25px);
      background-color: $color-dark-blue;
      padding: 10px 20px;
      text-align: center;
      color:$color-white;
      strong{
        font-size:18px;
      }
    }
  }

  #plakaContent, #sasiContent{
    display: none;
    input{
      text-transform: uppercase;
      margin-bottom: 10px;
    }
  }

  // Listeleme Sayfası
  &.listing{
    .content-wrapper{
      background-color: #ecf0f3;
    }
    .listing-count{
      padding: 0;
      text-align: center;
      span{
        font-size:25px;
        font-weight: bold;
        color:$color-dark-blue;
        background-color: $color-white;
        padding: 10px 20px;
        width: auto !important;
        display: inline-block;
        margin-bottom: 14px;
        border:2px solid #CCC;
      }

      p{
        font-size:15px !important;
      }
    }
    .item{
      background-color: $color-white;
      padding: 5px 20px 20px 20px;
      margin-bottom: 20px;
      .title{
        font-size:12px;
        color:$color-black;
        margin-top: 10px;
      }
      .test-content{
        padding: 0;
        margin: 0;
        li{
          display: inline-block;
          background-color: $color-gray-1;
          padding: 3px 10px;
          margin-bottom: 4px;
          font-size:12px;
          color:$color-white;
        }
      }
      .button{
        display: block;
        text-align: center;
        @include border-radius(0);
        background-color:#23b35f;
        margin: 0;
      }
    }
    .booking-confirmation-page{
      .button{
        background-color: $color-blue;
        color:$color-white;
      }
    }
  }

  // Ödeme Sayfası
  &.payments{
    .appointment-summary{
      background-color: #ecf0f3;
      @include border-radius(10px);
      .boxed-widget{
        background: transparent;
        h3{
          border-color:#ccc;
        }
        ul{
          li{
            padding:4px 0;
            border-bottom:1px dashed #ccc;
          }
        }
      }
    }
  }

  // Ödeme Başarılı
  &.success{
    #main-content{
      a.button{
        background-color: #23b35f;
        color:$color-white;
      }
    }
  }
  // Ödeme Başarısız
  &.error{
    #main-content{
      .fa{
        color:$color-red;
      }
      a.button{
        background-color: $color-blue;
        color:$color-white;
      }
    }
  }
}